import React from 'react';
import './App.scss'
import {Layout} from "src/hoc/Layout";



export default function App() {


    return (<>
        <Layout/>
        </>)
}
